<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="9"
        md="9"
      >
        <b-row class="create-edit-form">
          <b-col cols="12">
            <h1 class="custom-header-title pr-1 mb-0">
              Vendor Evaluations & Rankings
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right mb-1"
        md="3"
        cols="3"
      >
        <b-button
          v-if="canViewThisAction('generate-evaluation', 'Vendor')"
          variant="success"
          :to="{ name: 'purchasing-vendors-generate-evaluation' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create New Evaluation Report</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-dropdown
                id="dropdown-form-purchase-request"
                ref="filter_dropdown_vendor_evaluation"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <evaluation-filters
                  :period-filter.sync="periodFilter"
                  :entity.sync="entity"
                  :entity-options="businessEntityOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="periodFilter || entity.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="entityVal in entity"
                  :key="entityVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromEntityFilter(entityVal)"
                  >
                    <span class="text-capitalize">{{ resolveEntityName(entityVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="periodFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="periodFilter = ''"
                  >
                    {{ periodFilter }}
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refEvaluationTable"
        class="position-relative event_list"
        :class="{ low_items: currentDataLength < 4 }"
        :items="fetchEvaluation"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('evaluation-view', 'Vendor')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id"
              :to="{ name: 'purchasing-vendors-evaluation-detail', params: { id: data.item._id } }"
            >
              {{ data.item.stringID }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.stringID }}
            </b-button>
          </div>
        </template>

        <template #cell(period)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.period }}</span>
          </div>
        </template>

        <template #cell(entity)="data">
          <div class="text-nowrap">
            <p
              v-for="(entityVal, key) in data.item.entity"
              :key="key"
            >
              {{ entityVal.name }}
            </p>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveEvaluationStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <!-- <template
          v-if="canViewThisAction('show', 'Evaluation')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canViewThisAction('evaluation-view', 'Vendor')"
              :to="{ name: 'purchasing-vendors-evaluation-detail', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
          </b-dropdown>
        </template> -->

      </b-table>
    </b-card>

    <div
      v-if="totalEvaluation > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalEvaluation"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BPagination, BFormTag,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import useEvaluationList from './useEvaluationList'
import evaluationStoreModule from './evaluationStoreModule'
import EvaluationFilters from './EvaluationFilters.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    // BDropdownItem,
    BPagination,
    BInputGroup,
    BFormTag,
    BInputGroupAppend,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    EvaluationFilters,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bulkStatus: 'approved',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusRadioOptions: [
        { title: 'Pending', code: 'pending' },
        { title: 'Approved', code: 'approved' },
        { title: 'Draft', code: 'draft' },
      ],
      user: store.state.auth.userData,
      required,
      businessEntityOptions: [],
    }
  },

  beforeMount() {
    this.$http.get('purchase/purchase-request/create/extra-options')
      .then(response => {
        this.businessEntityOptions = response.data.businessEntityOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_vendor_evaluation.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_vendor_evaluation.show(true)
    },
    removeFromEntityFilter(item) {
      const index = this.entity.indexOf(item)
      if (index > -1) {
        this.entity.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.periodFilter = ''
      this.entity = []
    },
    resolveEntityName(id) {
      const entity = this.businessEntityOptions.find(o => o._id === id)
      if (entity) {
        return entity.name || ''
      }
      return ''
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Warning!',
        text: ' Are you sure you want to delete this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteEvaluation()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const EVALUATION_APP_STORE_MODULE_NAME = 'app-vendor-evaluations'

    // Register module
    if (!store.hasModule(EVALUATION_APP_STORE_MODULE_NAME)) store.registerModule(EVALUATION_APP_STORE_MODULE_NAME, evaluationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATION_APP_STORE_MODULE_NAME)) store.unregisterModule(EVALUATION_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchEvaluation,
      tableColumns,
      perPage,
      currentPage,
      evaluationData,
      totalEvaluation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEvaluationTable,
      refetchData,
      entity,
      periodFilter,

      toDeletedID,
      deleteEvaluation,
      currentDataLength,
      resolveEvaluationStatusVariant,
    } = useEvaluationList()

    return {

      // Sidebar

      fetchEvaluation,
      tableColumns,
      perPage,
      currentPage,
      evaluationData,
      totalEvaluation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEvaluationTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteEvaluation,
      currentDataLength,

      // Filter
      entity,
      periodFilter,
      canViewThisAction,
      resolveEvaluationStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
